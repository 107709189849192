import React, { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Field, Label, Switch } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  BoltIcon,
  ChevronDoubleUpIcon,
  SparklesIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import Logo from "../img/logo.jpg";
import Linkedin from "../img/linkedin.svg";
import Mail from "../img/mail.svg";
import VSC from "../img/vsc.png";
import "../styles/Home.css";

function Home() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const navigation = [
    { name: "Accueil", href: "home" },
    { name: "À propos", href: "#about" },
    { name: "Services", href: "#prices" },
    { name: "Contact", href: "#contact" },
  ];

  const plans = [
    {
      name: "Site Vitrine",
      price: "500-1500€",
      features: [
        "Plus rapide à réaliser",
        "Bien pour les petites entreprises",
        "Design simple et épuré",
        "Facile à mettre à jour",
        "Pas de fonctionnalités complexes",
        "Pas de base de données requise",
        "Pas de gestion de contenu requise",
      ],
    },
    {
      name: "Site Intéractif",
      price: "1500-5000€",
      features: [
        "Plus de fonctionnalités",
        "Bien pour les moyennes entreprises",
        "Design plus complexe",
        "Facile à mettre à jour",
        "Base de données requise",
        "Gestion de contenu requise",
        "Création de compte utilisateur",
      ],
    },
    {
      name: "Site Personnalisé",
      price: "0 - 5000€+",
      features: [
        "Fonctionnalités personnalisées",
        "Pour tout type d'entreprise",
        "Design sur mesure",
        "Facile à mettre à jour",
        "Base de données au choix",
        "Gestion de contenu au choix",
        "Rendez-vous pour discuter du projet",
      ],
    },
  ];

  const features = [
    {
      name: "Rapidité de service",
      description:
        "Nous vous offrons des services de qualité, dans les délais les plus courts possible.",
      icon: BoltIcon,
    },
    {
      name: "Performance garantie",
      description:
        "Les sites réalisés sont performants et optimisés pour un meilleur référencement.",
      icon: ChevronDoubleUpIcon,
    },
    {
      name: "Qualité optimale",
      description:
        "La qualité est au coeur de nos préoccupations. Nous vous offrons des services de qualité.",
      icon: SparklesIcon,
    },
  ];

  function sendMail() {
    const firstName = document.getElementById("first-name").value;
    const lastName = document.getElementById("last-name").value;
    const company = document.getElementById("company").value;
    const email = document.getElementById("email").value;
    const phoneNumber = document.getElementById("phone-number").value;
    const message = document.getElementById("message").value;
    const country = document.getElementById("country")
      ? document.getElementById("country").value
      : "";

    const mailtoLink = `mailto:contact@fmenoni.com?subject=Contact Form Submission&body=
        First Name: ${encodeURIComponent(firstName)}%0D%0A
        Last Name: ${encodeURIComponent(lastName)}%0D%0A
        Company: ${encodeURIComponent(company)}%0D%0A
        Email: ${encodeURIComponent(email)}%0D%0A
        Phone Number: ${encodeURIComponent(phoneNumber)}%0D%0A
        Country: ${encodeURIComponent(country)}%0D%0A
        Message: ${encodeURIComponent(message)}`;

    window.location.href = mailtoLink;
  }

  const [inputValue, setInputValue] = useState("");
  const [commands, setCommands] = useState([
    { type: "info", text: "$ npm install profile" },
    { type: "output", text: '+ loading "Fabian Menoni"' },
    { type: "output", text: '+ loading "Fullstack Developer"' },
    {
      type: "output",
      text: (
        <>
          + loading "LinkedIn :{" "}
          <a
            href="https://www.linkedin.com/in/fab-men/"
            className="text-green-400"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fabian Menoni
          </a>
          "
        </>
      ),
    },
    {
      type: "output",
      text: (
        <>
          + loading "Email :{" "}
          <a href="mailto:contact@fmenoni.com" className="text-green-400">
            contact@fmenoni.com
          </a>
          "
        </>
      ),
    },
    { type: "info", text: "$ loaded successfully!" },
  ]);

  const [isFocused, setIsFocused] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setCommands([...commands, { type: "info", text: `$ ${inputValue}` }]);
      setInputValue(""); // Reset input after submitting
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    // Clear input value when focus is lost
    setInputValue("");
  };

  const checkCommands = () => {
    const sanitizedValue = document
      .getElementById("terminal")
      .value.replace(/[^a-zA-Z0-9_-]/g, "");
    setInputValue(sanitizedValue);

    if (sanitizedValue === "cat") {
      window.open("https://www.fmenoni.com/yeah_Bounce.webm", "_blank");
    } else if (sanitizedValue === "rick") {
      window.open("https://www.youtube.com/watch?v=dQw4w9WgXcQ", "_blank");
    } else if (sanitizedValue === "creator-of-this-website") {
      window.open("https://www.chatgpt.com/", "_blank");
    } else if (sanitizedValue === "worm") {
      window.open("http://www.staggeringbeauty.com/", "_blank");
    } else if (sanitizedValue === "i-dont-code-this-thing-does-it-for-me") {
      window.open("https://floatui.com", "_blank");
    }
  };

  const footerNavs = [
    {
      href: "home",
      name: "Accueil",
    },
    {
      href: "#about",
      name: "À propos",
    },
    {
      href: "#prices",
      name: "Services",
    },

    {
      href: "#contact",
      name: "Contact",
    },
  ];
  window.addEventListener("load", function () {
    document.getElementById("terminal").blur();
  });

  return (
    <div className="bg-neutral-950" id="container">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          aria-label="Global"
          className="flex items-center justify-between p-6 lg:px-8"
        >
          <div className="flex lg:flex-1">
            <a href="home" className="-m-1.5 p-1.5">
              <span className="sr-only">FMenoni</span>
              <img alt="Logo FMenoni" src={Logo} className="w-auto h-8" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="w-6 h-6" />
            </button>
          </div>
          <div className="justify-end hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-200"
              >
                {item.name}
              </a>
            ))}
          </div>
        </nav>
        <Dialog
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full px-6 py-6 overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="home" className="-m-1.5 p-1.5">
                <span className="sr-only">FMenoni</span>
                <img alt="" src={Logo} className="w-auto h-8" />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="w-6 h-6" />
              </button>
            </div>
            <div className="flow-root mt-6">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="py-6 space-y-2">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
      <div className="relative min-h-screen px-6 isolate lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 overflow-hidden -top-40 -z-10 transform-gpu blur-3xl sm:-top-80"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              background:
                "linear-gradient(135deg, rgba(92, 32, 152, 0.8) 0%, rgba(132, 21, 149, 0.7) 50%, rgba(304, 31, 88, 0.8) 100%)",
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] opacity-40 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="flex items-center justify-center min-h-screen mx-auto max-w-7xl">
          <div className="flex flex-wrap justify-start">
            <div className="w-full sm:w-1/2 xl:w-1/2">
              <div className="text-left">
                <h1 className="text-4xl font-bold tracking-tight text-transparent bg-clip-text bg-gradient-to-b from-white to-gray-700 sm:text-6xl">
                  FMenoni Web Development
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-500">
                  Une société qui vous accompagne dans la création de votre site
                  web qui vous correspond et qui vous ressemble.
                </p>
                <div className="flex items-center justify-start mt-10 gap-x-6">
                  <a
                    className="bg-gradient-to-b from-neutral-900 to-neutral-950 text-zinc-300 py-2.5 px-4 rounded-full border border-gray-900 duration-150 font-medium text-center text-sm inline-block w-full hover:bg-gray-700 sm:w-auto"
                    href="#prices"
                  >
                    Découvrir
                  </a>
                  <a
                    href="#about"
                    className="text-sm font-semibold leading-6 text-gray-200"
                  >
                    En savoir plus <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end w-full xl:w-1/2">
              <div className="relative pt-4 sm:pt-10">
                {" "}
                {/* Ajout du padding top ici */}
                <div className="background-blob"></div>
                <aside className="w-full max-w-lg p-6 font-mono text-white border rounded-lg blurred-background border-neutral-900">
                  <div className="flex items-center justify-between">
                    <div className="flex space-x-2 text-red-500">
                      <div className="w-3 h-3 bg-red-500 rounded-full"></div>
                      <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                      <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                    </div>
                  </div>

                  <div className="mt-4">
                    {commands.map((command, index) => (
                      <p
                        key={index}
                        className={
                          command.type === "info"
                            ? "text-green-400"
                            : "text-white"
                        }
                      >
                        {command.text}
                      </p>
                    ))}

                    <div className="flex items-center text-green-400">
                      $
                      <input
                        className="w-full ml-2 text-white bg-transparent border-none outline-none"
                        value={inputValue}
                        id="terminal"
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyPress={handleKeyPress}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onInput={checkCommands}
                        autoFocus
                      />
                      {/* Cursor block animation */}
                      {!isFocused && (
                        <span className="absolute w-2 h-5 ml-1 bg-green-400 left-8 cursor-block cursor"></span>
                      )}
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-20rem)]"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              background:
                "linear-gradient(135deg, rgba(92, 32, 152, 0.8) 0%, rgba(132, 21, 149, 0.7) 50%, rgba(304, 31, 88, 0.8) 100%)",
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] opacity-40 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
      <div className="py-24 overflow-hidden sm:py-32" id="about">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="grid max-w-2xl grid-cols-1 mx-auto gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-600">
                  FMenoni
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-transparent bg-clip-text bg-gradient-to-b from-white to-gray-700 sm:text-4xl">
                  Qui sommes-nous ?
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Nous sommes une équipe de développeurs web passionnés par la
                  création de sites web qui vous proposons des offres adaptées à
                  vos besoins
                </p>
                <dl className="max-w-xl mt-10 space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-400">
                        <feature.icon
                          aria-hidden="true"
                          className="absolute w-5 h-5 text-indigo-600 left-1 top-1"
                        />
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <img
              alt="Product screenshot"
              src={VSC}
              width={2432}
              height={1442}
              className="w-[48rem] max-w-none rounded-xl items-center z-50 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            />
          </div>
        </div>
      </div>
      <section className="py-14" id="prices">
        <div className="max-w-screen-xl px-4 mx-auto text-gray-600 md:px-8">
          <div className="relative max-w-xl mx-auto sm:text-center">
            <h3 className="text-3xl font-semibold text-transparent sm:text-4xl bg-clip-text bg-gradient-to-b from-white to-gray-700">
              Nos différentes offres
            </h3>
            <div className="max-w-xl mt-3">
              <p>
                Nous vous proposons différentes offres pour la création de votre
                site web. Vous pouvez choisir celle qui vous correspond le
                mieux.
              </p>
            </div>
          </div>
          <div className="justify-center gap-6 mt-16 space-y-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3">
            {plans.map((item, idx) => (
              <div
                key={idx}
                className="relative flex flex-col items-stretch flex-1 p-8 border rounded-xl border-neutral-800"
              >
                <div>
                  <span className="font-medium text-indigo-600">
                    {item.name}
                  </span>
                  <div className="mt-4 text-3xl font-semibold text-gray-400">
                    {item.price}
                  </div>
                </div>
                <ul className="py-8 space-y-3">
                  {item.features.map((featureItem, idx) => (
                    <li key={idx} className="flex items-center gap-5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 text-indigo-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      {featureItem}
                    </li>
                  ))}
                </ul>
                <div className="flex items-end flex-1">
                  <button className="z-50 w-full px-3 py-3 text-sm font-semibold text-white duration-150 bg-indigo-600 rounded-lg hover:bg-indigo-500 active:bg-indigo-700">
                    Je suis intéressé(e)
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div
        className="relative px-6 py-20 text-gray-100 isolate lg:px-8"
        id="contact"
      >
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[-5rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-10rem]"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="relative right-full -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-2 rotate-[-230deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-20rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold tracking-tight text-transparent sm:text-4xl bg-clip-text bg-gradient-to-b from-white to-gray-700">
            Nous contacter
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-500">
            Vous avez des questions ou vous souhaitez en savoir plus sur nos
            offres ? N'hésitez pas à nous contacter.
          </p>
        </div>
        <form
          id="contact-form"
          action="#"
          method="POST"
          onSubmit={(e) => {
            e.preventDefault();
            sendMail();
          }}
          className="max-w-xl mx-auto mt-16 sm:mt-20"
        >
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <label
                htmlFor="first-name"
                className="block text-sm font-semibold leading-6"
              >
                Prénom
              </label>
              <div className="mt-2.5">
                <input
                  id="first-name"
                  name="first-name"
                  type="text"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 bg-gray-800 text-gray-100 px-3.5 py-2 shadow-sm ring-1 ring-gray-600 placeholder:text-gray-500 focus:ring-2 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last-name"
                className="block text-sm font-semibold leading-6"
              >
                Nom
              </label>
              <div className="mt-2.5">
                <input
                  id="last-name"
                  name="last-name"
                  type="text"
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 bg-gray-800 text-gray-100 px-3.5 py-2 shadow-sm ring-1 ring-gray-600 placeholder:text-gray-500 focus:ring-2 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block text-sm font-semibold leading-6"
              >
                Société
              </label>
              <div className="mt-2.5">
                <input
                  id="company"
                  name="company"
                  type="text"
                  autoComplete="organization"
                  className="block w-full rounded-md border-0 bg-gray-800 text-gray-100 px-3.5 py-2 shadow-sm ring-1 ring-gray-600 placeholder:text-gray-500 focus:ring-2 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-semibold leading-6"
              >
                Email
              </label>
              <div className="mt-2.5">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 bg-gray-800 text-gray-100 px-3.5 py-2 shadow-sm ring-1 ring-gray-600 placeholder:text-gray-500 focus:ring-2 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="phone-number"
                className="block text-sm font-semibold leading-6"
              >
                Numéro de téléphone
              </label>
              <div className="relative mt-2.5">
                <div className="absolute inset-y-0 left-0 flex items-center">
                  <label htmlFor="country" className="sr-only">
                    Country
                  </label>
                  <select
                    id="country"
                    name="country"
                    className="h-full py-0 pl-4 text-gray-400 bg-gray-800 border-0 rounded-md bg-none pr-9 focus:ring-2 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option>EU</option>
                    <option>US</option>
                    <option>AS</option>
                  </select>
                </div>
                <input
                  id="phone-number"
                  name="phone-number"
                  type="tel"
                  autoComplete="tel"
                  className="block w-full rounded-md border-0 bg-gray-800 text-gray-100 px-3.5 py-2 pl-24 shadow-sm ring-1 ring-gray-600 placeholder:text-gray-500 focus:ring-2 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-semibold leading-6"
              >
                Message
              </label>
              <div className="mt-2.5">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full rounded-md border-0 bg-gray-800 text-gray-100 px-3.5 py-2 shadow-sm ring-1 ring-gray-600 placeholder:text-gray-500 focus:ring-2 focus:ring-indigo-500 sm:text-sm"
                  defaultValue={""}
                />
              </div>
            </div>
          </div>
          <div className="mt-10">
            <button
              type="submit"
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-gray-100 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Envoyer
            </button>
          </div>
        </form>
      </div>
      <footer className="max-w-screen-xl px-4 py-5 mx-auto text-gray-500 md:px-8">
        <div className="max-w-lg sm:mx-auto sm:text-center">
          <img src={Logo} className="w-32 sm:mx-auto" />
          <p className="leading-relaxed mt-2 text-[15px]">
            Une société qui vous accompagne dans la création de votre site web
            qui vous correspond et qui vous ressemble. Nous sommes à votre
            écoute pour vous conseiller et vous accompagner dans votre projet.
          </p>
        </div>
        <ul className="items-center justify-center mt-8 space-y-5 sm:flex sm:space-x-4 sm:space-y-0">
          {footerNavs.map((item, idx) => (
            <li className=" hover:text-gray-800">
              <a key={idx} href={item.href}>
                {item.name}
              </a>
            </li>
          ))}
        </ul>
        <div className="items-center justify-between mt-8 sm:flex">
          <div className="mt-4 sm:mt-0">
            &copy; 2024 FMenoni <a href="legal">All rights reserved</a>.
          </div>
          <div className="mt-6 sm:mt-0">
            <ul className="flex items-center space-x-4">
              <li className="flex items-center justify-center w-10 h-10 border rounded-full">
                <a href="https://www.linkedin.com/in/fab-men/" target="_blank">
                  <img src={Linkedin} alt="Linkedin" className="w-5 h-5" />
                </a>
              </li>

              <li className="flex items-center justify-center w-10 h-10 border rounded-full">
                <a
                  href="https://www.linkedin.com/company/fmenoni/"
                  target="_blank"
                >
                  <img src={Linkedin} alt="Linkedin" className="w-5 h-5" />
                </a>
              </li>

              <li className="flex items-center justify-center w-10 h-10 border rounded-full">
                <a href="mailto:contact@fmenoni.com">
                  <svg
                    className="w-6 h-6 text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 4H3C2.44772 4 2 4.44772 2 5V19C2 19.5523 2.44772 20 3 20H21C21.5523 20 22 19.5523 22 19V5C22 4.44772 21.5523 4 21 4Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 5L12 13L2 5"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <style jsx>{`
          .svg-icon path,
          .svg-icon polygon,
          .svg-icon rect {
            fill: currentColor;
          }
        `}</style>
      </footer>
    </div>
  );
}

export default Home;
